import styled from "styled-components"
import media from "styled-media-query"

export const ListWrapper = styled.section`
  min-height: 90vh;
  body#grid & {
    background-color: var(--borders);
    border-bottom: 1px solid var(--borders);
    display: grid;
    grid-area: posts;
    grid-gap: 1px;
    /* grid-template-columns: repeat(auto-fit, minmax(380px, 1fr)); */
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  ${media.lessThan("large")`
    min-height: 80vh;
  `}
`
